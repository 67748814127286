<template>
  <a-layout-header :class="['app-layout_header', navType ? 'bg_none' : '']">
    <div class="app-header">
      <div class="app-header_left">
        <div class="app-header_logo_v">
          <div class="logo"></div>
        </div>
        <div class="app-header_logo">
          <i class="logo"></i>
          <span class="title">智能空地视频综合应用系统</span>
        </div>
        <ul>
          <li :class="{ 'left-active': $route.path == menu.path }" v-for="(menu, i) in menuList" :key="i">
            <router-link :to="menu.path">{{ menu.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="app-header_right">
        <app-header-dropdown></app-header-dropdown>
      </div>
    </div>
  </a-layout-header>
</template>
<script>
import { AppHeaderDropdown } from '@/components/layout';
import { onMounted, reactive, toRefs } from 'vue';
import bus from '../../bus';

export default {
  components: {
    AppHeaderDropdown
  },
  props: {
    navType: { type: Number, default: 0 }
  },
  setup() {
    const state = reactive({
      menuList: []
    });
    // const userInfo = JSON.parse(localStorage.getItem('SS_userinfo'));

    const setMenuList = () => {
      // if (!userInfo.isAdmin) return;
      let tempArr = JSON.parse(localStorage.getItem('authMenuList'));
      let authMenuList = [];
      if (tempArr && tempArr.length) {
        tempArr.map(item => {
          authMenuList.push({ name: item.menuName, path: item.path });
        });
      }
      state.menuList = [
        ...new Set(
          [
            {
              name: '首页',
              path: '/'
            }
          ].concat(authMenuList)
        )
      ];
      console.log('menuList', state.menuList);
    };

    bus.on('addRoutes', () => {
      setMenuList();
    });

    onMounted(() => {
      setMenuList();
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>
<style lang="less" scoped>
@import '~@less/public.less';
@logo: '~@imgs/layout/logo.png';
@logo_v: '~@imgs/layout/logo_v.png';
@logo_blue: '~@imgs/layout/logo_blue.png';

.app-layout_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  padding: 0;
  line-height: 72px;
  background: #fff;
  color: #000;
  z-index: 999;

  .app-header {
    margin: 0 auto;
    padding: 0 20px;
    width: 1440px;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    &_logo {
      display: flex;
      align-items: center;

      .logo {
        width: 33px;
        height: 28px;
        background: url('@{logo_blue}') no-repeat center / 100% 100%;
      }

      .title {
        margin-left: 12px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
      }
    }

    &_left {
      display: flex;
      align-items: center;
      .left-active {
        font-weight: bold;
        font-family: PingFangSC-Semibold, PingFang SC;
      }
      .left-active::after {
        content: '';
        display: inline-block;
        width: 15px;
        margin-top: 5px;
        padding-top: 3px;
        background-color: #007fff;
        border-radius: 2px;
      }
      ul {
        display: flex;
        margin-left: 30px;
        li {
          padding: 0 25px;
          display: flex;
          cursor: pointer;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          line-height: 1.5;
        }
      }
    }

    &_right {
      position: relative;
      display: flex; /*Flex布局*/
      display: -webkit-flex; /* Safari */
      align-items: center; /*指定垂直居中*/

      .ant-input {
        .search-public();
      }
    }
  }

  &.bg_none {
    background: transparent;
    color: #fff;

    .app-header_logo {
      .logo {
        background: url('@{logo}') no-repeat center / 100% 100%;
      }
    }
    .app-header_logo_v {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .logo {
        width: 160px;
        height: 72px;
        background: url('@{logo_v}') no-repeat center / 100% 100%;
      }
    }

    .app-header_left {
      .left-active::after {
        background-color: #fff;
      }
    }
  }
}
</style>
