<template>
  <a-dropdown :trigger="['click']" placement="bottomRight" overlayClassName="app-header_dropdown">
    <a class="ant-dropdown-link" @click.prevent>
      <a-avatar class="avatar" :src="userInfo.avatar" />
      <div class="name">
        {{ userInfo.nickName }}
        <DownOutlined />
      </div>
    </a>
    <template #overlay>
      <a-menu>
        <template v-if="!admin">
          <a-menu-item key="0">
            <router-link to="/personal-center"> <UserOutlined />&nbsp;&nbsp;个人中心</router-link>
          </a-menu-item>
          <a-menu-item key="1">
            <router-link to="/in-mail"> <MessageOutlined />&nbsp;&nbsp;站内信
            <sup style="color: red" v-if="Boolean(sharedDataStore.unread)">{{sharedDataStore.unread}}</sup></router-link>
          </a-menu-item>
        </template>
        <template v-else>
          <a-menu-item key="2">
            <router-link to="/"> <RollbackOutlined />&nbsp;&nbsp;返回主页</router-link>
          </a-menu-item>
        </template>
        <a-menu-item key="3" class="app-header_logout">
          <a @click="handleLogOut">退出</a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { computed, ref , watch} from 'vue';
import { useStore } from 'vuex';
import { DownOutlined, UserOutlined, MessageOutlined, RollbackOutlined } from '@ant-design/icons-vue';
import Cookies from 'js-cookie';
import { sharedDataStore } from '/src/store/sharedData';
export default {
  components: {
    DownOutlined,
    UserOutlined,
    MessageOutlined,
    RollbackOutlined
  },
  props: {
    admin: { type: Boolean, default: false },
  },
  setup() {
    let registerSocket = computed(() => store.state.registerSocket);
    const store = useStore();
    const userInfo = JSON.parse(localStorage.getItem('SS_userinfo'));
    // 退出
    const handleLogOut = () => {
      Cookies.remove('SS_token');
      localStorage.clear();
      location.href = 'http://10.45.0.116:8080/epoint-sso-web/rest/oauth2/logout?redirect_uri=http://2.45.177.47:8090';
      registerSocket.value.disconnect();
    };
    
    return {
      userInfo,
      handleLogOut,
      sharedDataStore
    };
  }
};
</script>

<style lang="less" scoped>
.ant-dropdown-link {
  width: 147px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .name {
    margin-left: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 20px;
  }

  &:hover {
    color: inherit;
  }
}
</style>
<style lang="less">
.app-header_dropdown {
  border-top: 3px solid #007fff;

  .ant-dropdown-menu-item {
    padding: 10px 30px;

    a {
      font-size: 14px;
      color: #666666;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
    }

    &-active,
    &:hover {
      background-color: transparent;
      a {
        color: #007fff;
      }
    }

    &.app-header_logout {
      text-align: center;
      background: #f6f6f6;
    }
  }

  &::before {
    content: '';
    width: 0;
    position: absolute;
    height: 0;
    background-color: transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #007fff;
    left: 50%;
    transform: translateX(-50%);
    top: -11px;
    opacity: 1;
    z-index: 999;
  }
}
</style>
